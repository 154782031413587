import { Button, ButtonSizes } from "@roc-digital/ui-web";
import { CommentItem } from "./CommentItem";
import { Comment } from "@roc-digital/types";
import React from "react";

interface CommentListProps {
  comments: Comment[];
  canLoadMore?: boolean;
  loading?: boolean;
  loaded?: boolean;
  onLoadMore: () => void;
  onEdit: (comment: Comment) => void;
  renderRepliesComponent?: (props: {comment: Comment, triggerNewReply?: number}) => any;
}

export function CommentList(props: CommentListProps) {

  const items = React.useMemo(() => {
    return props.comments.map(c => (
      <CommentItem key={c.id}
        comment={c}
        onEdit={props.onEdit}
        renderRepliesComponent={props.renderRepliesComponent}
      />
    ))
  }, [props.comments])

  return <div className="flex flex-col gap-2">

  {items}

  {props.loading ? (
    <div className="flex flex-row justify-center font-italic font-medium text-[12px] text-[#545454]">
      ...loading comments
    </div>
  ) : null}

  {!props.loading && props.loaded && !props.comments.length ? (
    <div className="flex flex-row justify-center font-italic font-medium text-[12px] text-[#545454]">
      No comments. Be the first to share something.
    </div>
  ) : null}

  {props.canLoadMore ? (
    <div className="flex flex-row justify-center">
      <Button
        size={ButtonSizes.medium}
        clickEvent={props.onLoadMore}
        customClassName="rounded-lg h-[42px]">Load More</Button>
    </div>
  ) : null}
</div>
}