import { RuiNode, RuiReactComponentProps, ButtonAttributes } from '@roc-digital/rich-ui';
import { Button, ButtonVariants, ButtonProps } from '../web/Button';
import React from 'react';

export function ButtonElement(props: RuiReactComponentProps) {
  let data: Partial<ButtonAttributes> | undefined = props.node.data;

  const baseProps = React.useMemo(() => getButtonPropsFromNode(props.node), [props.node]);
  return <Button
    {...baseProps}
    clickEvent={() => props.context.doAction(data?.action || '', data?.url)}
  />
}

export function getButtonPropsFromNode(node: RuiNode): ButtonProps {
  let data: Partial<ButtonAttributes> | undefined = node.data;

  let variant: ButtonVariants | undefined = ButtonVariants.primary;
  let customClassName: string | undefined = undefined;
  let customTextClassName: string | undefined = undefined;

  if(data?.variant === 'secondary') {
    variant = ButtonVariants.secondary;
    customClassName = '!bg-[#e5e5e5]'
    customTextClassName = 'text-[#1E222A] !text-sm'
  } else if(data?.variant === 'warning') {
    variant = ButtonVariants.destructive;
  } else if(data?.variant === 'outline') {
    variant =  undefined
    customClassName = 'bg-[#24AAE1] border-2 border-white'
    customTextClassName = 'text-white !text-sm'
  }
  return {
    title: data?.label || '',
    size: node.data?.size,
    variant,
    customClassName,
    customTextClassName
  }
}