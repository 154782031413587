import React from "react";

export function useOnKeyPress(key: string, cb: (event: KeyboardEvent) => void, debug?: boolean) {
  React.useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      console.log('[seOnKeyPress] Key Press:', event.key);
      if(event.key === key) {
        cb(event);
      }
    }
    window.addEventListener('keyup', listener);

    return () => {
      window.removeEventListener('keyup', listener);
    }
  }, [])
}