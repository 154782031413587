import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMethod } from '@roc-digital/ui-lib';
import { LoginAndCheckAccount } from '@/logic';
import { LoginForm, LandingPageView } from '@/components/elements';
import { UserOnboardingFlow } from './UserOnboardingFlow';
import { useAuthState } from '@/logic';
import { ForgotPasswordForm } from '@/components/elements/ForgotPasswordForm';
import { useEffect } from 'react';

export interface SignInProps {
  loginMode: 'login' | 'sign-up';
}

function isTestMode() {
  try {
    return window?.location?.toString().includes('test');
  } catch(err) {
    return false;
  }
}

export function SignIn(props: SignInProps) {
  const auth = useAuthState();
  const nav = useNavigate();
  const [params, setParams] = useSearchParams();
  const loginQuery = useMethod(LoginAndCheckAccount, {
    onSuccess: (state) => {
      if(state.onBoarded && !isTestMode()) {
        nav('/trending')
      }
    },
    onError: (err) => {
      console.log(err.message);
      if(err.message === 'Login cancelled') {
        return;
      }

      toast.error(err.toString() || 'Incorrect username or password.');
    }
  });

  useEffect(() => {
    let redirect = params.get('redirect');
    if (redirect && redirect === 'foryou') {
      toast.info('Please login or sign up to access personalized content.');
    }
    if (redirect && redirect === 'local') {
      toast.info('Please login or sign up to access local content.');
    }
    if (redirect && redirect === 'account') {
      toast.info('Please login to access your account info.');
    }

    if(document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }    
  }, []);

  let content = <></>;

  if(auth.loading || auth.authenticating) {
    content = <>
      <div style={{width: '320px', height: '500px'}}></div>
    </>;
  } else if(!auth.authenticated || auth.isAnonymous || loginQuery.loading) {
      content = <LoginForm
        mode={props.loginMode}
        loading={loginQuery.loading}
        onLogin={(ops) => loginQuery.run(ops).catch(() => {})}
      />
  } else if(!auth.onBoarded || isTestMode()) {
    content = <UserOnboardingFlow/>;
  } else {
    content = <Navigate to="/trending"/>
  }

  return <LandingPageView>
    {content}
  </LandingPageView>;
}
