import { RenderUiBlock, RenderUiBlockProps } from './RenderUiBlock';
import { usePromotional } from '@/logic/promotionals';
import { ButtonAttributes } from '@roc-digital/rich-ui';
import { Promotional } from '@roc-digital/types';
import { useNavigate } from 'react-router';

export interface PromoUiBlockProps {
  block: keyof Promotional;
  errorContent?: RenderUiBlockProps['errorContent'];
  onAction?: RenderUiBlockProps['onAction'];
  getTextToken?: (p: Promotional) => any;
}

export function PromoUiBlock(props: PromoUiBlockProps) {
  const {promotional} = usePromotional();

  const navigate = useNavigate();

  const onAction = (action: ButtonAttributes['action']) => {
    if(action === 'visit-billing') {
      navigate('/account/billing')
    } else if(action === 'login') {
      navigate('/login')
    } else if(action === 'signup') {
      navigate('/signup')
    }
  }

  return <RenderUiBlock
    blockId={promotional?.[props.block] as any}
    errorContent={props.errorContent}
    textTokens={{monthlyPrice: '$9.28'}}
    onAction={props.onAction || onAction as any}
  />
}
