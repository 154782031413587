import {
  upvoteIcon,
  upvoteFillIcon,
  downvoteIcon,
  downvoteFillIcon,
  bookmarkIcon,
  bookmarkFillIcon,
  shareIcon,
  ArticleAction,
} from '@roc-digital/ui-lib';
import { Icon } from '../Icon';
import { BodyDark } from '../Typefaces';

export interface ActionBarProps {
  voted?: 'up' | 'down' | null | undefined;
  bookmarked?: boolean;
  upvotes: number;
  downvotes: number;
  articleId: string;
  comments?: number;
  inlineComments?: boolean;
  onAction: (action: ArticleAction) => void;
}

export function ActionBar(props: ActionBarProps) {

  const commentsLink = <div className='text-sm text-[#24aae1] cursor-pointer'
  onClick={() => props.onAction({ action: 'comment', articleId: props.articleId })}
>
  {props.comments || 0} Comments
</div>;

  const bar = <div className='action-bar flex flex-row gap-2 items-center' style={{ flexShrink: 0 }}>
    <div className='flex items-center content-center justify-center rounded-half border border-background'>

      <div
        className='flex flex-row h-8 gap-1 px-2 items-center content-center justify-center cursor-pointer'
        onClick={() => props.onAction({ action: 'up-vote', articleId: props.articleId })}
      >
        <Icon size="custom" src={props.voted === 'up' ? upvoteFillIcon : upvoteIcon} />
        <BodyDark className={'text-admin-muted text-lg dark:text-white'}>{props.upvotes}</BodyDark>
      </div>

      <div
        onClick={() => props.onAction({ action: 'down-vote', articleId: props.articleId })}
        className='flex flex-row h-8 gap-1 px-2 items-center content-center justify-center cursor-pointer' style={{ borderLeft: `1px solid #F7F7F7` }}
      >
        <Icon size="custom" src={props.voted === 'down' ? downvoteFillIcon : downvoteIcon} />
        <BodyDark className={'text-admin-muted text-lg dark:text-white'}>{props.downvotes}</BodyDark>
      </div>

    </div>

    {props.inlineComments ? (
      commentsLink
    ) : null}

    <div className='flex-grow'></div>

    <div
      onClick={() => props.onAction({ action: 'bookmark', articleId: props.articleId })}
      className='w-8 h-8 flex items-center content-center justify-center rounded-full border border-background cursor-pointer'
    >
      <Icon size="custom" src={props.bookmarked ? bookmarkFillIcon : bookmarkIcon} />
    </div>

    <div
      onClick={() => props.onAction({ action: 'share', articleId: props.articleId })}
      className='w-8 h-8 flex items-center content-center justify-center rounded-full border border-background cursor-pointer'
    >
      <Icon size="custom" src={shareIcon} />
    </div>

  </div>;

  if (props.inlineComments) {
    return bar;
  } else {
    return <div>
      {commentsLink}
      {bar}
    </div>
  }
}
