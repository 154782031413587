import { Heading1 } from "@roc-digital/ui-web";
import { CommentInput } from "./CommentInput";
import { CommentList } from "./CommentList";
import { Comment } from "@roc-digital/types";
import { closeModal, openModal, useComments } from "@roc-digital/ui-lib";
import { useAuthState } from "@/logic";
import { useNavigate } from "react-router";
import { EditModal, ConfirmDeleteModal, SendReplyModal } from "./Modals";
import React from "react";


interface CommentsProps {
  refId?: string;
}

export function Comments(props: CommentsProps) {
  if(!props.refId) return null;

  return <ResetableContainer
    key={props.refId}
    refId={props.refId}/>
}

export function ResetableContainer(props: {refId: string}) {
  const comments = useComments(props.refId);
  const auth = useAuthState();
  const navigate = useNavigate();

  const onDelete = (comment: Comment) => {
    openModal(<ConfirmDeleteModal
      onCancel={closeModal}
      onConfirm={() => {
        closeModal();
        comments.delete(comment)
      }}
    />, 'small')
  }

  const onEdit = (comment: Comment) => {
    openModal(<EditModal
      body={comment.body}
      onCancel={closeModal}
      onDelete={() => onDelete(comment)}
      onSave={(body) => {
        closeModal();
        comments.edit(comment, body)
      }}
    />, 'small')
  }

  return <div className="flex flex-col gap-4 mx-2">
    <Heading1 id="comments" className="dark:text-white mt-6 text-action !font-extrabold !text-[20px] !leading-9 !tracking-tight !font-chivo">
      Comments
    </Heading1>
    <CommentList
      comments={comments.comments}
      canLoadMore={comments.canLoadMore}
      loading={comments.loading}
      loaded={comments.loaded}
      onLoadMore={comments.loadMore}
      onEdit={onEdit}
      renderRepliesComponent={RenderReplies}
    />
    <CommentInput
      onLogin={(type) => {
        if(type === 'login') {
          navigate('/login')
        } else {
          navigate('/signup')
        }
      }}
      needsLogin={!auth.authenticated}
      onShare={comments.send}
    />
  </div>
}

function RenderReplies(props: {comment: Comment, triggerNewReply?: number}) {
  const comments = useComments(props.comment.ref_id, props.comment.id);

  const onDelete = (comment: Comment) => {
    openModal(<ConfirmDeleteModal
      onCancel={closeModal}
      onConfirm={() => {
        closeModal();
        comments.delete(comment)
      }}
    />, 'small')
  }

  const onEdit = (comment: Comment) => {
    openModal(<EditModal
      body={comment.body}
      onCancel={closeModal}
      onDelete={() => onDelete(comment)}
      onSave={(body) => {
        closeModal();
        comments.edit(comment, body)
      }}
    />, 'small')
  }

  const onReply = (comment: Comment) => {
    openModal(<SendReplyModal
      comment={comment}
      onCancel={closeModal}
      onSend={(body) => {
        closeModal();
        comments.send(body)
      }}
    />, 'small')
  }

  React.useLayoutEffect(() => {
    if(props.triggerNewReply && !usedTriggers[props.triggerNewReply]) {
      usedTriggers[props.triggerNewReply] = true;
      onReply(props.comment)
    }
  }, [props.triggerNewReply])
  
  return <CommentList
    comments={comments.comments}
    canLoadMore={comments.canLoadMore}
    loading={comments.loading}
    loaded={comments.loaded}
    onLoadMore={comments.loadMore}
    onEdit={onEdit}
  />
}
const usedTriggers = {} as any;